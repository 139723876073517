import React from 'react';
import './FooterHome.css'

function FooterLower() {
  return (
    <div>
    <div className="footerLower py-3">
      <div className="container text-start d-flex">
      HOMES-DEVELOPERS © 2024 
      </div>
      </div>
    </div>
  )
}

export default FooterLower