import React from "react";
import "./Quotation.css";
import WhatsApp from "../ContactUs/WhatsApp";

const Quotation = () => {
  return (
    <div>
      <div className="container">
        <div className="quotation">
          <h2>Quotation request</h2>
          <h3>
            Contact us and we will create an individual offer for your request.
          </h3>
          <div>
            <WhatsApp
              phoneNumber="+923064444766"
              message="Hello, I need assistance."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quotation;
