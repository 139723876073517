import React from "react";
import "./Unique.css";

function Unique() {
  return (
    <div>
      <div className="Unique text-start">
        <div className="container">
          <div>
            <h2 className="py-2">What Makes Us Different?</h2>
            <h3 className="py-2">Commitment to Quality</h3>
            <p className="py-2">We are dedicated to delivering the highest quality properties that exceed industry standards, ensuring lasting value for our customers.</p>
          </div>
          <div>
            <h3 className="py-2">Innovation and Design</h3>
            <p className="py-2">We are at the forefront of innovation in real estate design, creating spaces that are not only functional but also aesthetically pleasing and future-proof.</p>
          </div>
          <div>
            <h3 className="py-2">Customer-Centric Approach</h3>
            <p className="py-2">Our customers are at the heart of everything we do. We listen to their needs, understand their aspirations, and strive to exceed their expectations.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Unique;
