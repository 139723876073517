import React from "react";
import "./FooterHome.css";
import { BiMap } from "react-icons/bi";
import { MdOutlineCall } from "react-icons/md";
import { BiTime } from "react-icons/bi";
import FooterLower from "./FooterLower";
import { Link } from "react-router-dom";

function FooterHome() {
  return (
    <div className="footer">
      <div className="footerUpper p-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-xs-12 col-sm-6">
              <h3>Services</h3>
              <ul>
                <li>
                  Property Development
                </li>
                <li>
                  Property Sales and Marketing
                </li>
                <li>
                  Property Management
                </li>
                <li>
                  Real Estate Consulting
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-xs-12 col-sm-6">
              <h3>Info</h3>
              <ul>
                <li>
                  <Link to="about">About</Link>
                </li>
                <li>
                  <Link to="contact">Contact</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-xs-12 col-sm-12">
              <h3>Office</h3>
              <ul>
                {/* <li >
                  <a href="#" className="d-flex">
                    <BiMap className="footer-icons" />
                    Im Taubengrund 3-5, 65451 Kelsterbach
                  </a>
                </li> */}
                <li>
                  <MdOutlineCall className="footer-icons" />
                  0306-4444766 or 0300-1399913

                </li>
                {/* <li className="d-flex align-items-center">
                    <BiTime className="footer-icons" />
                    24/7 Helpline
                  </li> */}
                {/* <li className="Social pt-4"><a href="#">FACEBOOK</a></li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <FooterLower />
    </div>
  );
}

export default FooterHome;
