import React from 'react';
import FooterLower from '../components/Footer/FooterLower';
import ServicesServices from '../components/ServicesServices/ServicesServices';
import ServicesCategories from '../components/ServicesCategories/ServicesCategories';
import GpsTracking from '../components/GpsTracking/GpsTracking';
import ServicesContent from '../components/ServicesContent/ServicesContent';

function Services() {
  return (
    <div>
        <ServicesContent />
        <ServicesCategories />
        {/* <ServicesServices /> */}
        {/* <GpsTracking /> */}
        <FooterLower />
    </div>
  )
}

export default Services