import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from './pages/Home';
import Company from './pages/Company';
import Contact from './pages/Contact';
import Services from './pages/Services';
import NavBar from './components/NavBar/NavBar';
import { useEffect } from 'react';
import ScrollToTop from './components/ScrollToTop';


function App() {

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  return (
    <div className="App">
    
      <BrowserRouter>
      <ScrollToTop />
      <NavBar />
        <Routes>
          <Route exact path='/'  element={<Home />}/>
          <Route path='/about' element={<Company />}/>
          <Route path='/services' element={<Services />}/>
          <Route path='/contact' element={<Contact />}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
