import React, { useState } from "react";
import { MdOutlineCall } from "react-icons/md";
import { Link } from "react-router-dom";
import "./NavBar.css";
import logo from "../../assets/images/Logo.png";
import OffCanvas from "./OffCanvas";

function NavBar() {
  const [navbar, setNavbar] = useState(false);

  const hideNavTop = () => {

    if (window.scrollY >= 20) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  // useEffect(() => {
  //   changeBackground()
  //   // adding the event when scroll change background
  //   window.addEventListener("scroll", changeBackground)
  // })
  window.addEventListener("scroll", hideNavTop);
  return (
    <div>
    <div>
      <OffCanvas />
    </div>
    <div className="fixed smooth">
      <div className="container">
      
        <div className={navbar===true? "hidden":"navbar-top-contact" }>
          Phone:
          <Link to="">
            <MdOutlineCall className="footer-icons" />
            0306-4444766
          </Link>
        </div>
        <div className={navbar=== true ? "navbar navbar-expand-lg bg-light p-1":"navbar navbar-expand-lg bg-light p-4"}>
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo03"
              aria-controls="navbarTogglerDemo03"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <Link className="navbar-brand p-0 m-0" to="/">
              <img src={logo} alt="logo"/>
            </Link>
            <div
              className="collapse navbar-collapse justify-content-end"
              id="navbarTogglerDemo03"
            >
              <ul className="navbar-nav mb-2">
                <li className="nav-item">
                  <Link className="nav-link " to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item ms-3">
                  <Link className="nav-link" to="/about">
                    About Us
                  </Link>
                </li>
                <li className="nav-item ms-3">
                  <Link className="nav-link" to="/services">
                    Services
                  </Link>
                </li>
                <li className="nav-item ms-3">
                  <Link className="nav-link" to="/contact">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default NavBar;
