import React from 'react';
import './ServicesContent.css'

function ServicesContent() {
  return (
    <div className='ServicesContent'>
      <div className='container responsive'>
        <div className="py-5">
          <h2 >Services</h2>
          <p className="pt-5">Homes Developers offers a comprehensive range of real estate services designed to meet the diverse needs of our clients. From property development to sales and marketing, property management, and real estate consulting, we provide end-to-end solutions that exceed expectations. Our commitment to quality, innovation, and customer satisfaction sets us apart, ensuring that every project is executed with precision and attention to detail. Whether you're looking to buy, sell, or invest in real estate, Homes Developers is your trusted partner for all your real estate needs.</p>
        </div>


      </div>
    </div>
  )
}

export default ServicesContent