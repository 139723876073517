import React from 'react';
import MainHome from '../components/mainHome/MainHome';
import Quotation from '../components/quotation/Quotation';
import ServicesHome from '../components/ServicesHome/ServicesHome';
import MapHome from '../components/MapHome/MapHome'
import FooterHome from '../components/Footer/FooterHome';
import PropertyTypes from '../components/PropertyTypes/PropertyTypes';
import ProjectsHome from '../components/ProjectsHome/Projects';

function Home() {
  return (
    <div>
      <MainHome />
      <ServicesHome />
      <PropertyTypes />
      <ProjectsHome />
      <Quotation />
      <MapHome />
      <FooterHome />
    </div>
  )
}

export default Home