// PropertyTypes.js
import React from 'react';
import { FaLandmark, FaHome, FaCity, FaBuilding } from 'react-icons/fa';
import './PropertyTypes.css'

const PropertyTypes = () => (
    <div className='propertyType'>
        <h3 className="animated">Property Types</h3>
        <div className="d-flex">

            <div className="propertyTypeLeft w-50">
                <div className="bg-dull">
                    <div className="content">
                        <h3>Plots</h3>
                        <p>
                            Discover a variety of plots available for residential and commercial purposes.
                        </p>
                    </div>
                </div>
            </div>
            <div className="propertyTypeRight w-50">
                <div className="bg-dull">
                    <div className="content">
                        <h3>Homes</h3>
                        <p>
                            Find your perfect home from our wide range of residential properties.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div className='d-flex'>
            <div className="propertyTypeLeft1 w-50">
                <div className="bg-dull">
                    <div className="content">
                        <h3>Skylines</h3>
                        <p>
                            Explore modern skylines that shape the future of urban living.
                        </p>
                    </div>
                </div>
            </div>
            <div className="propertyTypeRight1 w-50">
                <div className="bg-dull">
                    <div className="content">
                        <h3>Commercial Properties</h3>
                        <p>Invest in commercial properties that offer great returns and strategic locations.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default PropertyTypes;
