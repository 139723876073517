import React from 'react';
import ContactUs from '../components/ContactUs/ContactUs';
import ContactUsForm from '../components/ContactUsForm/ContactUsForm';
import FooterLower from '../components/Footer/FooterLower';
import MapHome from '../components/MapHome/MapHome';

function Contact() {
  return (
    <div>
        <div>
            <div>
                <ContactUs />
                <ContactUsForm />
                <MapHome />
                <FooterLower />
            </div>
        </div>
    </div>
  )
}

export default Contact