import React from 'react'
import alkabeer from '../../assets/images/phase-2-main-gate-new.webp'

const ProjectsHome = () => {
    return (
        <div className='py-5'>
            <div className='container'>
                <div>

                    <h3 className='animated'>Our Projects</h3>
                    <div>
                        <div className="row row-cols-lg-4 row-cols-md-4 row-cols-xs-1">
                            <div className="col">
                                <div className="card">
                                    <img src={alkabeer} className="card-img-top" alt="Al-Kabir Town Main Gate" />
                                    <div className="card-body">
                                        <h6 className="card-title">Al-Kabir Town</h6>
                                        <p className="card-text">Al-Kabir Town is a well-planned residential and commercial project located in Lahore. It offers modern amenities such as gated community security, parks, commercial areas, schools, mosques, and community centers. Al-Kabir Town provides an ideal living environment with a range of housing options to meet different needs.</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col">
                        <div className="card">
                            <FaKey className="card-icon" />
                            <div className="card-body">
                                <h6>Property Sales</h6>
                                <p>Expert guidance and support for buying and selling properties.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card">
                            <FaBuilding className="card-icon" />
                            <div className="card-body">
                                <h6>Property Management</h6>
                                <p>Professional management services for your real estate investments.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card">
                            <FaSearchLocation className="card-icon" />
                            <div className="card-body">
                                <h6>Real Estate Consulting</h6>
                                <p>Expert advice and guidance on real estate investments and strategies.</p>
                            </div>
                        </div>
                    </div> */}
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default ProjectsHome