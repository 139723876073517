import React from "react";

const WhatsApp = ({ phoneNumber, message }) => {
  const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
    message
  )}`;
  return (
    <a href={whatsappUrl} target="_blank" rel="noopener noreferrer">
      <button className="whatsapp-button">
        <i class="fa fa-whatsapp" aria-hidden="true"></i>
        Chat on WhatsApp
      </button>
    </a>
  );
};

export default WhatsApp;
