import React from "react";
import "./mainHome.css";
import { Link } from "react-router-dom";
import { MdOutlineCall } from "react-icons/md";

function MainHome() {
  return (
    <div className="main">
      <div
        id="carouselExampleControls"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner ">
          <div className="carousel-item active item1">
            <div className="">
              <div className="container ">
                <div className="contact">

                  <MdOutlineCall className="footer-icons" />
                  <Link to="">
                    <h2>0306-4444766</h2>
                    <h4>Call Us Now</h4>
                  </Link>
                </div>
                <div className=" text-start main-content">
                  <h2>Homes Developers</h2>
                  <h3 className="py-3">Building Dreams, One Home at a Time!</h3>
                  <p className="pb-3">
                    Welcome to Homes Developers. We specialize in creating vibrant communities with our diverse real estate portfolio, including plots, homes, and commercial properties. Our commitment to quality and innovation ensures every project is a landmark. Whether you're seeking a plot, a dream home, or a commercial space, we're your partner in shaping the future. Join us in building a better tomorrow, one property at a time.
                  </p>
                  {/* <a href="#">Learn More</a> */}
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item item2 ">
            <div className="container ">
              <div className="contact">

                <MdOutlineCall className="footer-icons" />
                <Link to="">
                  <h2>0306-4444766</h2>
                  <h4>Call Us Now</h4>
                </Link>
              </div>
              <div className=" text-start main-content">
                <h3 className="py-3">Crafting Homes, Creating Memories</h3>
                <p className="">
                  Discover our collection of beautifully crafted homes designed to elevate your lifestyle. From elegant apartments to spacious villas, each home is meticulously planned and constructed to offer comfort, convenience, and luxury. Find your perfect home with Homes Developers and start creating memories that last a lifetime.
                </p>
              </div>
            </div>
          </div>
          <div className="carousel-item item3">
            <div className="container">
              <div className="contact">

                <MdOutlineCall className="footer-icons" />
                <Link to="">
                  <h2>0306-4444766</h2>
                  <h4>Call Us Now</h4>
                </Link>
              </div>
              <div className=" text-start main-content">
                <h3 className="py-3">Redesigning Skylines, Revitalizing Communities</h3>
                <p className="pb-3">
                  Our commitment to excellence extends beyond homes to include dynamic skylines and commercial properties. We specialize in creating iconic developments that redefine urban landscapes and rejuvenate communities. Whether you're looking for a commercial space to grow your business or a skyline-defining development, Homes Developers has the expertise and vision to bring your project to life.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className=" carousel-control-buttons">
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>

      </div>
    </div>
  );
}

export default MainHome;
