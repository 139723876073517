import React from 'react';
import './CompanyContent.css';

function CompanyContent() {
  return (
    <div>
      <div className='container CompanyContent'>
        <div className="responsive">
          <h2 >About Us</h2>
          <p className="py-5">
            At Homes Developers, we are more than just a real estate company – we are builders of dreams, architects of communities, and stewards of innovation. With a legacy of excellence spanning multiple years, we have earned a reputation for integrity, quality, and customer satisfaction.<br /> Our journey began with a vision to redefine the real estate landscape by creating spaces that inspire, enrich, and endure. Over the years, we have evolved and adapted to the changing needs of our customers and the dynamic nature of the industry. Today, we stand as a symbol of trust and reliability, committed to delivering exceptional properties that exceed expectations.
          </p>
        </div>
      </div>
    </div>
  )
}

export default CompanyContent