import React from "react";
import "./ServicesHome.css";
import PropertySale from '../../assets/images/peroprty-sale.jpeg'
import Consulting from '../../assets/images/consulting.jpeg'
import PrpertyManagement from '../../assets/images/wholesaling-real-estate.png'
import PropertyDevelopment from '../../assets/images/Property-development-tax.webp'

function ServicesHome() {
  return (
    <div className="services py-5">
      <div className="container">
        <h3 className="animated">Our Services</h3>
        <div className="row row-cols-lg-4 row-cols-md-2 row-cols-sm-1">
          <div className="col">
            <div className="card d-flex flex-column h-100">
              <img src={PropertyDevelopment} className="card-icon" />
              <div className="card-body flex-grow-1">
                <h6>Property Development</h6>
                <p>Specializing in premium residential and commercial properties.</p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card d-flex flex-column h-100">
              <img src={PropertySale} className="card-icon" />
              <div className="card-body flex-grow-1">
                <h6>Property Sales</h6>
                <p>Expert guidance and support for buying and selling properties.</p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card d-flex flex-column h-100">
              <img src={PrpertyManagement} className="card-icon" />
              <div className="card-body flex-grow-1">
                <h6>Property Management</h6>
                <p>Professional management services for your real estate investments.</p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card d-flex flex-column h-100">
              <img src={Consulting} className="card-icon" />
              <div className="card-body flex-grow-1">
                <h6>Real Estate Consulting</h6>
                <p>Expert advice and guidance on real estate investments and strategies.</p>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  );
}

export default ServicesHome;
