import React, { useState } from "react";
import "./ContactUsForm.css";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ContactUsForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceId = "service_zvrjs7p";
    const templateId = "template_8djeifn";
    const publicKey = "xyDOPYCY8xQ-DF5vf";

    const templateParams = {
      from_name: name,
      from_email: email,
      from_phone: phone,
      to_name: "Homes Developers Team",
      message: message,
    };

    // Send the email using EmailJS
    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        toast.success("Message sent successfully!");
        setName("");
        setEmail("");
        setMessage("");
      })
      .catch((error) => {
        toast.error("Failed to send message.");
      });
  };

  return (
    <div>
      <ToastContainer />
      <div className="ContactUsForm container">
        <div className="cantactUsContainer">
          <h2 className="pb-4">Contact</h2>
          <form name="sentMessage" validate onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="form-control"
                    placeholder="Your Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                  <p className="help-block text-danger"></p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="form-control"
                    placeholder="Your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <p className="help-block text-danger"></p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <input
                    type="phone"
                    id="phone"
                    name="phone"
                    className="form-control"
                    placeholder="Your Phone Number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                  />
                  <p className="help-block text-danger"></p>
                </div>
              </div>
            </div>
            <div className="form-group">
              <textarea
                name="message"
                id="message"
                className="form-control"
                required
                cols="30"
                rows="10"
                value={message}
                placeholder="Enter Your Message"
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
              <p className="help-block text-danger"></p>
            </div>
            <div id="success"></div>
            <button type="submit" className="btn SendButton">
              <span>Send Message</span>
            </button>
          </form>
          {/* <div className="row">
            <div className="col">
              <input
                type="string"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="Name:"
              />
            </div>
            <div className="col">
              <input
                type="email"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="E-mail:"
              />
            </div>
            <div className="col">
              <input
                type="phone"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="Phone:"
              />
            </div>
          </div>
          <div className="mt-4">
            <textarea
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="8"
              placeholder="News:"
            ></textarea>
          </div>
          <div className="SendButton">
            <a href="#">SEND</a>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default ContactUsForm;
