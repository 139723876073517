import React from "react";
import { BiMap } from "react-icons/bi";
import { MdOutlineCall, MdOutlineMail } from "react-icons/md";
import { BiTime } from "react-icons/bi";
import "./ContactUs.css";

function ContactUs() {
  return (
    <div>
      <div className="container contactUs ">
        <h2 className="py-3">Contact</h2>
        <div>
          <div className="row text-start">
            <div className="col">
              {/* <h3>Office Kelsterbach</h3> */}
              <ul>
                <li>
                  <MdOutlineMail className="footer-icons" />
                  homesdeveloper00@gmail.com
                  {/* <a href="#">
                    <BiMap className="footer-icons" />
                    Im Taubengrund 3-5, 65451 Kelsterbach
                  </a> */}
                </li>
                <li>
                  <MdOutlineCall className="footer-icons" />
                  <b>
                    0306-4444766 or 0300-1399913
                  </b>

                </li>
                {/* <li>
                  <BiTime className="footer-icons" />
                  24/7 Support
                </li> */}
              </ul>
            </div>
            {/* <div className="col">
              <h3>Hamburg</h3>
              <ul>
                <li>
                  <a href="#">
                    <BiMap className="footer-icons" />
                    About Office Kelsterbach
                  </a>
                </li>
                <li>
                  <a href="#">
                    <MdOutlineCall className="footer-icons" />
                    About Office Kelsterbach
                  </a>
                </li>
                <li>
                  <BiTime className="footer-icons" />
                  24/7 Support
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
