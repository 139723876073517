import React from "react";
import "./ServicesCategories.css";

function ServicesCategories() {
  return (
    <div className="ServicesCategories">
      <div className="container py-5">
        <div class="row row-cols-1 row-cols-md-2 g-4">
          <div class="col">
            <div class="card h-100 col-style">
              <div class="card-body">
                <h5 class="card-title">
                  Property Development</h5>
                <p class="card-text">
                  We specialize in developing premium residential and commercial properties, from concept to completion. Our team of experts ensures that every project is executed with precision and attention to detail, resulting in exceptional spaces that exceed expectations.
                </p>
              </div>
            </div>
          </div>
          <div class="col ">
            <div class="card h-100 col-style">
              <div class="card-body">
                <h5 class="card-title">Property Sales and Marketing</h5>
                <p class="card-text">Our dedicated sales and marketing team is committed to helping you find the perfect property or investment opportunity. We provide comprehensive support throughout the buying process, ensuring a seamless and hassle-free experience.</p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100 col-style">
              <div class="card-body">
                <h5 class="card-title">Property Management</h5>
                <p class="card-text">
                  We offer professional property management services to ensure that your investment is well-maintained and profitable. From tenant screening to maintenance and repairs, we take care of everything so you can enjoy peace of mind.
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100 col-style">
              <div class="card-body">
                <h5 class="card-title">Real Estate Consulting</h5>
                <p class="card-text">
                  Our real estate consulting services are designed to provide you with expert advice and guidance on all aspects of the real estate industry. Whether you're a first-time buyer or an experienced investor, we can help you make informed decisions and maximize your returns.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServicesCategories;
