import React from 'react';
import './MapHome.css';

function MapHome() {
  return (
    <section className='maps'>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d435521.4079594928!2d74.0541966328554!3d31.48263522311786!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39190483e58107d9%3A0xc23abe6ccc7e2462!2sLahore%2C%20Punjab%2C%20Pakistan!5e0!3m2!1sen!2s!4v1657177714938!5m2!1sen!2s" width="100%" height="456" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </section>
  )
}

export default MapHome